<template>
  <div v-if="table">
    <div class="row">
      <div class="col-md-8">
        <small style="color: grey">
          {{ objectType }}
        </small>
        <h2 style="word-break: break-word; margin-top: 0px">
          {{ table.name }}
        </h2>
        <small style="word-break: break-all">
          {{ objectName }}&nbsp;
          <Tooltip
            direction="top"
            :contents="`Copy ${objectType} full name`"
            class="inline"
          >
            <a>
              <i
                v-clipboard:copy="objectName"
                class="fa fa-copy customlink"
              />
            </a>
          </Tooltip>
        </small>
      </div>

      <div class="col-md-4">
        <div style="float: right">
          <Bookmark
            :keyValue="keyValue"
            style="font-size: 2em"
            :loadingWidth="20"
          />
        </div>
        <div style="float: right;">
          <Tooltip :contents="`Download ${objectType} metadata`">
            <div style="font-size: 2em">
              <DownloadData
                :fileName="`${table.name}-metadata`"
                iconSize="fa-sm"
                downloadTitle=""
                :fetchData=" () => ({
                  cluster: table.cluster,
                  database: table.database,
                  schema: table.schema,
                  name: table.name,
                  description: table.description,
                  dataOwners: (table.programmatic_descriptions.find(p => p.source === 'squad_email') || {}).text,
                  slackChannels: (table.programmatic_descriptions.find(p => p.source === 'slack_channel') || {}).text,
                  domain_id: (table.programmatic_descriptions.find(p => p.source === 'domain_id') || {}).text,
                  status: (table.programmatic_descriptions.find(p => p.source === 'table_status') || {}).text,
                  pofDuration: (table.programmatic_descriptions.find(p => p.source === 'pof_duration') || {}).text,
                  pofDurationFilter: (table.programmatic_descriptions.find(p => p.source === 'pof_duration_filter') || {}).text,
                  pofReferenceColumn: (table.programmatic_descriptions.find(p => p.source === 'pof_reference_column') || {}).text,
                  tags: table.tags.map((o) => o.tag_name),
                  stewardEmails: table.owners.map((o) => o.email),
                  columns: table.columns
                })
                "
              />
            </div>
          </Tooltip>
        </div>
        <div style="float: right;">
          <Label
            v-if="
                (customFields.is_share === 'True' &&
                !(
                  producer_object.cluster.toUpperCase() ===
                  table.cluster.replace('.eu-west-1', '').toUpperCase()
                ))
            "
            text="READ-ONLY"
            status="danger"
            style="margin-right: 1rem; margin-left: 16px; margin-top: 0.5rem"
          />
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-md-4" style="height: 100px;">
        <div style="float: left">
          <h6 class="inline" style="color: grey">
            Status
          </h6>

          <Tooltip
            class="customlink inline"
            direction="top"
            style="margin: 5px;"
            contents="Click to know more about stable Data sets"
          >
            <a
              href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/1257179058/Creating+Stable+Data+Sets"
              target="_blank"
            >
              <IconInformationCircle size="md" weight="fill" />
            </a>
          </Tooltip>

          <Tooltip
            class="customlink inline"
            direction="top"
            contents="History"
          >
            <IconSynchronizeArrowClock size="md" @onClick="tableStatusHistoryOn = true" />
          </Tooltip>
          <br />

          <div v-if="!loadingTableStatus">
            <Tooltip
              :contents="`Status of this ${objectType}. Only stewards are allowed to edit.`"
              class="inline"
            >
              <div v-click-outside="closeTableStatusDropdown">
                <span
                  v-if="customFields.table_status === 'stable'"
                  class="dropdown-toggle customlink"
                  style="font-weight: bold; color: green"
                  @click="tableStatusClick = !tableStatusClick"
                >
                  Stable
                  <span
                    v-if="isVisible.stable"
                    class="caret"
                  />
                </span>
                <span
                  v-if="customFields.table_status === 'investigable'"
                  class="dropdown-toggle customlink"
                  style="font-weight: bold; color: #ffa237"
                  @click="tableStatusClick = !tableStatusClick"
                >
                  Investigable
                  <span
                    v-if="isVisible.investigable"
                    class="caret"
                  />
                </span>
                <span
                  v-if="customFields.table_status === 'internal'"
                  class="dropdown-toggle customlink"
                  style="font-weight: bold; color: #ff0000"
                  @click="tableStatusClick = !tableStatusClick"
                >
                  Internal
                  <span
                    v-if="isVisible.internal"
                    class="caret"
                  />
                </span>
                <span
                  v-if="customFields.table_status === 'not_set'"
                  class="dropdown-toggle customlink"
                  style="font-weight: bold; color: grey"
                  @click="tableStatusClick = !tableStatusClick"
                >
                  Not set
                  <span
                    v-if="isVisible.not_set"
                    class="caret"
                  />
                </span>
              </div>
            </Tooltip>
            <div v-if="isVisible.drop_down">
              <div
                class="dropdown"
                :class="tableStatusClick ? 'open' : 'close'"
              >
                <ul class="dropdown-menu" style="align-items: center">
                  <li align="center">
                    <a
                      style="font-weight: bold; color: green"
                      @click="processStableOptionSelect()"
                    >Stable</a>
                  </li>
                  <li align="center">
                    <a
                      style="font-weight: bold; color: #ffa237"
                      @click="updateTableStatus('investigable')"
                    >Investigable</a>
                  </li>
                  <li align="center">
                    <a
                      style="font-weight: bold; color: #ff0000"
                      @click="updateTableStatus('internal')"
                    >Internal</a>
                  </li>
                  <li align="center">
                    <a
                      style="font-style: italic; color: grey"
                      @click="updateTableStatus(null)"
                    >Not Set</a>
                  </li>
                </ul>
              </div>
            </div>
            <ConfirmBox
              v-if="showWarning"
              :title="`Unstable Dataset`"
              :isWarning=true
              :message="warningMessage"
              :showButtons=false
              @closeModal="showWarning = false; tableStatusClick = false"
            />
            <error-popup
              v-if="errorMessage"
              errorType="snackbar-danger"
              :message="errorMessage"
            />
          </div>
          <div
            v-else-if="!errorMessage"
            class="loading"
            style="padding-top: 4px"
          >
            <img src="spinner.svg" width="25" alt="loading" />
            Loading status...
          </div>
        </div>
        <br />
      </div>
      <div class="col-md-4" style="height: 100px">
        <h6 class="inline" style="color: grey">
          PII Status
        </h6>
        <span v-if="isPiiLoading" class="loading" style="padding-right: 25px">
          <img src="spinner.svg" width="25" alt="loading" />
          Loading PII Data...
        </span>
        <div v-else>
          <div v-if="isPiiExisting">
            <div v-if="!isPiiCurated" style="display: flex">
              <Tooltip direction="top" :contents="`${piiTooltip}`">
                <span style="color: #ffa23f">
                  <i class="fa fa-warning" />&nbsp;PII Detected
                </span>
              </Tooltip>
                &nbsp;&nbsp;
            </div>
            <div v-else class="piiDataWarning">
              <Tooltip direction="top" contents="Table contains PII Column">
                <i class="fa fa-id-card" />&nbsp;PII Exists
              </Tooltip>
            </div>
          </div>
          <div v-else style="color: grey">
            No PII Data detected
          </div>

          <div v-if="isPiiCurated" style="color: #35ad96; padding-top: 4px">
            <Tooltip direction="top" contents="PII Information is curated by the stewards">
              <i class="fa fa-check-circle-o" />&nbsp;&nbsp;PII Curated
            </Tooltip>
          </div>
          <div v-else>
            <Tooltip
              v-if="isUserSteward && !isObjectReadOnly"
              direction="top"
              contents="Click to mark the object as curated."
            >
              <button
                style="padding-left: 0px"
                class="btn-link btn-sm"
                @click="clickedPiiCurated = true"
              >
                Click to curate
              </button>
            </Tooltip>
            <div v-else style="color: rgb(255, 162, 63); padding-top: 4px">
              <Tooltip direction="top" contents="Stewards need to curate PII Information">
                <i class="fa fa-warning" />&nbsp;PII Not Curated
              </Tooltip>
            </div>
          </div>
        </div>

        <EditHistoryTable
          v-if="tableStatusHistoryOn"
          :entityId="`${keyValue}/table_status`"
          :entityType="'Table Status'"
          @closeModal="tableStatusHistoryOn = false"
        />
      </div>
      <div v-if="customFields.is_share === 'True' && !errorShareConsumers" class="col-md-4" style="height: 100px">
        <div
          v-if="
            producer_object.cluster.toUpperCase() ===
              table.cluster.replace('.eu-west-1', '').toUpperCase()
          "
        >
          <h6 class="inline" style="color: grey">
            Shared To
          </h6>
          <br />
          <a
            v-for="shareConsumer in shareConsumers"
            :key="shareConsumer['shares_info.shared_to']"
          >
            <!-- eslint-disable -->
                <a
                  @click="
                    setSelectedTreeItem({
                      selectedTreeItem: `${shareConsumer[
                        'shares_info.shared_to'
                      ].toLowerCase()}.eu-west-1.${
                        shareConsumer['shares_info.consumer_database_name']
                      }.${shareConsumer[
                        'shares_info.object_schema'
                      ].toLowerCase()}.${shareConsumer[
                        'shares_info.object_name'
                      ].toLowerCase()}`
                    })
                  "
                >
                  <!-- eslint-enable -->
              <Tooltip
                direction="top"
                :contents="`This object is being shared to ${
                  shareConsumer['shares_info.shared_to']
                } PDW.
                                    Click here to accesss the shared object: \n Database: ${
                  shareConsumer[
                    'shares_info.consumer_database_name'
                  ]
                }, \n
                                    Schema: ${shareConsumer[
                                      'shares_info.object_schema'
                ].toLowerCase()}, View: ${shareConsumer[
                  'shares_info.object_name'
                ].toLowerCase()}`"
                class="inline"
              >
                {{ shareConsumer['shares_info.shared_to'] }}
              </Tooltip>
            </a>
          </a>
          <span v-if="loadingShareConsumers" align="right">
            <img src="spinner.svg" :width="30" alt="loading" />
          </span>
        </div>

        <div v-else>
          <h6 class="inline" style="color: grey">
            Shared From
          </h6>
          <br />
          <a
            @click="
              setSelectedTreeItem({
                selectedTreeItem: `${producer_object.cluster}.eu-west-1.${producer_object.database}.${producer_object.schema}.${producer_object.name}`
              })
            "
          >
            <Tooltip
              direction="top"
              :contents="`The ${objectType} is shared from ${producer_object.account} PDW.
                                    Click here to view the source Data object (Database: ${producer_object.database}, Schema: ${producer_object.schema}, Name: ${producer_object.name})`"
              class="inline"
            >
              {{ producer_object.account }}
            </Tooltip>
          </a>
        </div>
      </div>
      <div v-if="rowCount" class="col-md-4">
        <h6 class="inline" style="color: grey">
          Total rows
        </h6>
        <br />
        <span>{{ rowCount }}</span>
      </div>
    </div>
    <br />

    <div class="heading pull-right">
      <ConfirmBox
        v-if="clickedPiiCurated && isUserSteward"
        :title="`Mark as PII Curated`"
        :message="descriptionMessagePiiCurated"
        @confirmOperation="markAsPiiCurated()"
        @closeModal="clickedPiiCurated = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DownloadData from '../DownloadData';
import Bookmark from '../Bookmark';
import EditHistoryTable from './../EditHistoryTable';
import ConfirmBox from '../ConfirmBox';
import { IconSynchronizeArrowClock, IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import { Tooltip, Label } from '@cimpress/react-components';
import vClickOutside from 'v-click-outside';
import numeral from 'numeral';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import ErrorPopup from '../ErrorPopup.vue';

Vue.use(VueClipboard);
export default {
  name: 'TableHeader',

  inject: ['platformClient', 'userManager'],

  components: {
    Tooltip,
    Label,
    Bookmark,
    DownloadData,
    EditHistoryTable,
    IconSynchronizeArrowClock,
    IconInformationCircle,
    ErrorPopup,
    ConfirmBox
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    keyValue: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      piiTooltip: '',
      tableStatusClick: false,
      tableStatusHistoryOn: false,
      errorMessage: null,
      clickedPiiCurated: false,
      isPiiLoading: false,
      showWarning: false
    };
  },
  computed: {
    ...mapState({
      table: state => state.tables.tableInfo,
      loadingTableStatus: state => state.tables.loadingTableStatus,
      customFields: state => state.tables.customFields,
      missingStabilityCriteria: state => state.tables.missingStabilityCriteria,
      piiData: state => state.pdwPii.piiData,
      loadingShareConsumers: state => state.nala.loadingShareConsumers,
      shareConsumers: state => state.nala.shareConsumers,
      errorShareConsumers: state => state.nala.errorShareConsumers,
      isObjectReadOnly : state => state.tables.isObjectReadOnly
    }),
    warningMessage() {
      let message = `The dataset can't be marked as stable as it <b>doesn't</b> satisfy the <b>stability</b> criteria.` +
        ` In order to mark it as stable, you have to fill in the following fields.<br/><br/><div><ul style="padding-left: 40px">`;

      this.missingStabilityCriteria.forEach(stabilityCriteria => {
        message = message + `<li>${stabilityCriteria}</li>`;
      });

      message = message + `</ul></div><br />To know more about the stability criteria, refer to <a href='https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/1257179058/Stable+Data+Sets+-+Definition+and+Requirements'> Stable Dataset Documentation</a>`;
      return message;

    },
    isUserSteward() {
      const userEmail = this.userManager.userData.app_metadata.canonical_id.toLowerCase();
      return !!this.table.owners.find(owner => owner.email.toLowerCase() === userEmail);
    },
    producer_object() {
      return {
        account: this.customFields.producer_object_key
          .split('/')[2]
          .split('.')[0]
          .toUpperCase(),
        cluster: this.customFields.producer_object_key
          .split('/')[2]
          .split('.')[0],
        database: this.customFields.producer_object_key.split(':')[0],
        schema: this.customFields.producer_object_key
          .split('.')[2]
          .split('/')[0],
        name: this.customFields.producer_object_key.split('/')[3]
      };
    },
    isVisible() {
      return {
        stable:this.isUserSteward && !this.isObjectReadOnly,
        investigable:this.isUserSteward && !this.isObjectReadOnly,
        internal:this.isUserSteward && !this.isObjectReadOnly,
        not_set:this.isUserSteward && !this.isObjectReadOnly,
        drop_down:this.isUserSteward && !this.isObjectReadOnly,
      };
    },
    isPiiExisting() {
      let isPiiExistingForColumn = false;
      if (this.piiData && this.piiData.piiFields) {
        for (let piiField in this.piiData.piiFields) {
          isPiiExistingForColumn
            = this.piiData.piiFields[piiField].piiTagged === true
            || (this.piiData.piiFields[piiField].detectedPiiTypes
              && Object.keys(this.piiData.piiFields[piiField].detectedPiiTypes)
                .length > 0
              && !this.piiData.piiFields[piiField].piiCurated);
          if (isPiiExistingForColumn) {
            break;
          }
        }
      }
      return isPiiExistingForColumn;
    },
    isPiiMarkedByScanner() {
      for (let column in this.piiData.piiFields) {
        // Check if column contains PII
        if (
          (this.piiData.piiFields[column].detectedPiiTypes
            && Object.keys(this.piiData.piiFields[column].detectedPiiTypes)
              .length > 0)
          || this.piiData.piiFields[column].piiTagged === true
        ) {
          if (
            !(
              this.piiData.piiFields[column].detectedPiiTypes
              && Object.keys(this.piiData.piiFields[column].detectedPiiTypes)
                .length > 0
              && !this.piiData.piiFields[column].piiTagged
              && !this.piiData.piiFields[column].jsonFields
            )
          ) {
            return false;
          }
        }
      }
      return true;
    },
    getColumnCountDetectedByScanner() {
      let count = 0;
      if (this.piiData && this.piiData.piiFields) {
        for (let column in this.piiData.piiFields) {
          if (
            this.piiData.piiFields[column].detectedPiiTypes
            && Object.keys(this.piiData.piiFields[column].detectedPiiTypes)
              .length > 0
            && !this.piiData.piiFields[column].piiTagged
            && !this.piiData.piiFields[column].piiCurated
            && !this.piiData.piiFields[column].jsonFields
          ) {
            count++;
          }
        }
      }
      return count;
    },
    isPiiCurated() {
      return this.piiData && this.piiData.piiCurated;
    },
    descriptionMessagePiiCurated() {
      let columnCount = this.getColumnCountDetectedByScanner;
      return `Marking <b>${this.table.name}</b> object as PII curated indicates that<br/><ul><li>PII curating process for this object is completed and all columns were reviewed for being PII compliant.</li><li>All the columns that are detected as PII by the scanner (highlighted with &nbsp;<i class="fa fa-id-card" style="font-size:20px; color:#ee96cc;"/></i>&nbsp; badge) will be marked as <b>not</b> a PII column as no user action was performed.<ul><li>Number of columns detected by scanner as PII:  ${columnCount}</li></ul></li></ul><br/>Do you want to mark <b>${this.table.name}</b> curated as PII?`;
    },
    objectName() {
      return `"${this.table.database.toUpperCase()}"."${this.table.schema.toUpperCase()}"."${this.table.name.toUpperCase()}"`;
    },
    objectType() {
      return this.table.is_view ? 'View' : 'Table';
    },
    rowCount() {
      if (this.customFields.row_count) {
        // toUpperCase is used to convert "15 m" to "15 M"
        if(this.customFields.row_count < 1000)
          return numeral(this.customFields.row_count).format('0a');
        else
          return numeral(this.customFields.row_count)
          .format('0.00 a')
          .toUpperCase();
      }
      return 0;
    }
  },
  watch: {
    piiData: {
      immediate: true,
      handler() {
        this.getPiiBadgeTooltip();
      }
    }
  },
  methods: {
    ...mapActions({
      putTableStatus: 'tables/putTableStatus',
      patchPii: 'pdwPii/patchPii',
      setSelectedTreeItem: 'search/setSelectedTreeItem'
    }),
    closeTableStatusDropdown() {
      this.tableStatusClick = false;
    },
    processStableOptionSelect() {
      if (this.missingStabilityCriteria.length) {
        this.showWarning = true;
      } else {
        this.updateTableStatus('stable');
      }
    },
    async updateTableStatus(status) {
      this.showWarning = false;
      this.errorMessage = null;
      this.tableStatusClick = !this.tableStatusClick;
      try {
        await this.putTableStatus({
          platformClient: this.platformClient,
          keyValue: this.keyValue,
          tableStatus: status
        });
      } catch (err) {
        this.errorMessage
          = err.message
          || err.data.message
          || err.data.title
          || (err.status && `Error occurred with status code ${err.status}`)
          || 'Unknown error occurred';
        console.log(err);
      }
    },
    getPiiBadgeTooltip() {
      if (this.piiData) {
        this.piiTooltip = this.isPiiMarkedByScanner
          ? 'Our scanners detected possible PII data on this table, stewards should curate it.'
          : 'Table contains PII';
      }
    },
    async markAsPiiCurated() {
      this.clickedPiiCurated = false;
      this.isPiiLoading = true;
      this.errorMessage = null;
      let updatePiiData = [
        {
          op: 'replace',
          path: '/piiCurated',
          value: true
        }
      ];
      try {
        await this.patchPii({
          platformClient: this.platformClient,
          account: this.table.cluster.replace('.eu-west-1', ''),
          database: this.table.database.toUpperCase(),
          schema: this.table.schema.toUpperCase(),
          object: this.table.name.toUpperCase(),
          updatePiiData: updatePiiData
        });
      } catch (err) {
        this.errorMessage
          = err.message
          || err.data.message
          || err.data.title
          || (err.status && `Error occurred with status code ${err.status}`)
          || 'Unknown error occurred.';
        console.log(err);
      }
      this.isPiiLoading = false;
    }
  }
};
</script>
<style scoped>
.right-arrow {
  color: #999;
  font-size: 1.4em;
}
.text-mid {
  vertical-align: text-top;
}
.loading {
  display: inline-block;
}
</style>
