<template>
  <div>
    <div v-if="!isActiveSearch">
      <DataDiscoveryHeader />
      <br />
    </div>

    <SearchBar
      :initialFilters="searchInput.filters || {}"
      :initialSearch="searchInput.searchText || ''"
      @onSearchInputChange="handleSearchInputChange"
    />

    <div v-if="searching" align="center">
      <img src="spinner.svg" alt="loading" /><br />
      Searching...
    </div>

    <error-popup
      v-else-if="searchError"
      errorType="snackbar-danger"
      :message="searchError"
      :delay="6000"
      :onHide="clearSearchError"
    />

    <div v-if="!isActiveSearch">
      <br /><br />
      <div class="row">
        <div class="col-md-6">
          <RecenTablesSection />
        </div>
        <div class="col-md-6">
          <PopularTagsSection
            @onTagClick="
              (e) => {
                handleSearchInputChange({
                  filters: { tag: [e.tag] },
                  searchText: '',
                  trigger: true
                });
              }
            "
          />
        </div>
      </div>

      <br />
    </div>
    <!-- Show Total Results -->
    <div v-if="isActiveSearch && !searching && searchResult">
      <div class="row">
        <div class="col-sm-6 align-bottom">
          <div style="padding: 20px 0px 6px 6px">
            <span>Results
              <b style="color: grey">{{
                Math.min(searchResult.total_results, currentPage * 10 + 1)
              }}
                -
                {{
                  Math.min(searchResult.total_results, (currentPage + 1) * 10)
                }}</b>, total results
              <b style="color: grey">{{ searchResult.total_results }}</b></span>
          </div>
        </div>

        <div class="col-sm-6">
          <div style="padding: 20px 0px 6px 6px; float: right;">
            <DownloadData
              fileName="searchResults"
              downloadTitle="Download search results"
              :fetchData="downloadResults"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Listing search result -->
    <div>
      <ListGroup
        v-if="searchResult"
        :searchText="searchText"
        :filters="searchInput.filters"
      />
    </div>
    <div v-if="isActiveSearch && !searching && searchResult">
      <div class="row">
        <div class="col-sm-12">
          <div style="float: right">
            <Pagination
              v-if="pageCount > 0"
              :initialPage="currentPage"
              :pageCount="pageCount"
              :pagesOutsideElipses="1"
              :pagesBetweenElipses="2"
              @onPageChange="handlePageChange"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isActiveSearch" class="row">
      <div class="col-md-6">
        <OtherResources />
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Ajv from 'ajv';
const ajv = new Ajv();
import { Pagination } from '@cimpress/react-components';
import normalizeSearchFilters from '@/utils/normalizeSearchFilters';
import urlEncodeSeachInput from '@/utils/urlEncodeSearchInput';

import ListGroup from './ListGroup';
import SearchBar from './SearchBar';
import DownloadData from '../DownloadData';
import DataDiscoveryHeader from './DataDiscoveryHeader';
import PopularTagsSection from './PopularTagsSection';
import RecenTablesSection from './RecenTablesSection';

import OtherResources from './OtherResources';
import ErrorPopup from '../ErrorPopup.vue';

export default {
  name: 'Search',
  inject: ['platformClient'],
  components: {
    DataDiscoveryHeader,
    SearchBar,

    DownloadData,
    Pagination,
    ListGroup,

    PopularTagsSection,
    RecenTablesSection,
    OtherResources,
    ErrorPopup
  },
  data() {
    return {
      searchText: ''
    };
  },
  computed: {
    ...mapState({
      searchInput: state => state.search.searchInput,
      searching: state => state.search.searching,
      searchResult: state => state.search.searchResult,
      searchError: state => state.search.searchError,
      currentPage: state => state.search.currentPage
    }),
    pageCount() {
      return Math.ceil(this.searchResult.total_results / 10);
    },
    isActiveSearch() {
      return this.searchInput.searchText !== null;
    }
  },

  watch: {
    searchInput() {
      if (this.isActiveSearch) {
        this.performSearch();
      }
    },
    $route(to) {
      this.updateSearchStateFromUrl(to.query);
    }
  },
  created() {
    this.updateSearchStateFromUrl(this.$route.query);
  },
  methods: {
    ...mapActions({
      getSearchResults: 'search/getSearchResults'
    }),
    handleSearchInputChange(e) {
      const searchInput = {
        searchText: e.trigger ? e.searchText : this.searchInput.searchText,
        filters: e.filters
      };

      // update url only after search button is clicked
      if (e.trigger) {
        const encodedSearchInput = urlEncodeSeachInput(searchInput);
        if (this.$route.query.searchInput !== encodedSearchInput) {
          this.$router.push({
            query: { searchInput: encodedSearchInput }
          });
        }
      }
    },
    handlePageChange(e) {
      if (this.currentPage !== e.selected && !this.searching) {
        this.$router.push({
          query: {
            ...this.$router.currentRoute.query,
            page: e.selected
          }
        });
      }
    },
    async performSearch() {
      await this.getSearchResults({
        platformClient: this.platformClient,
        filters: normalizeSearchFilters(this.searchInput.filters),
        searchValue: this.searchInput.searchText || '',
        pageNumber: this.currentPage
      });
    },
    async downloadResults() {
      const postData = {
        search_request: {
          type: 'AND',
          filters: normalizeSearchFilters(this.searchInput.filters),
          fields: [
            'cluster',
            'database',
            'schema',
            'name',
            'description',
            'squad_email',
            'owner',
            'slack_channel',
            'domain_id',
            'table_status',
            'pof_duration',
            'pof_duration_filter',
            'pof_reference_column',
            'tags',
            'column_names'
          ]
        },
        query_term: this.searchInput.searchText || '',
        page_index: -1
      };

      const response = await this.platformClient.post(
        `${process.env.VUE_APP_AMUNDSEN_SEARCH_URL}/search_table`,
        postData
      );

      return response.data.results.map(table => {
        return {
          cluster: table.cluster,
          database: table.database,
          schema: table.schema,
          name: table.name,
          description: table.description,
          dataOwners: table.squad_email,
          stewardEmails: (table.owner || []),
          slackChannels: table.slack_channel,
          domain_id: table.domain_id,
          status: table.table_status,
          pofDuration: table.pof_duration,
          pofDurationFilter: table.pof_duration_filter,
          pofReferenceColumn: table.pof_reference_column,
          tags: table.tags.map(o => o.tag_name),
          columns: table.column_names
        };
      });
    },
    clearSearchError() {
      this.$store.commit('search/searchError', null);
    },
    updateSearchStateFromUrl(query) {
      // if searchInput query parameter is present we are in the context of search page
      // we are updating the states correspondingly
      if (!query.searchInput) {
        return;
      }

      this.$store.commit('search/currentPage', Number(query.page) || 0);

      try {
        const searchInputData = JSON.parse(query.searchInput);

        // schema for `filters` object with a list of filters that are only allowed
        const filtersObjectProperties = SearchBar.data().filtersData.reduce((props, filter) => {
          props[filter.name] = {
            type: 'array',
            items: {
              type: 'string'
            }
          };
          return props;
        }, {});
        // schema for the whole search input objet
        const searchInputSchema = {
          type: 'object',
          properties: {
            searchText: { type: 'string' },
            filters: {
              type: 'object',
              properties: filtersObjectProperties,
              additionalProperties: false
            }
          },
          additionalProperties: false
        };

        // validate received data against defined schema
        const valid = ajv.validate(searchInputSchema, searchInputData);
        if (!valid) {
          throw new Error(`Invalid search input format: ${ajv.errorsText()}`);
        }

        this.$store.commit('search/searchInput', {
          searchText: searchInputData.searchText || '',
          filters: searchInputData.filters || {}
        });
      } catch (e) {
        console.warn(`Could not validate searchInput query parameter: ${e}`);

        // if searchInput param has an invalid format, we show an error
        this.$store.commit('search/searchError', 'Could not parse search input from URL. Please check if URL has been copied properly .');
        // clean up current search input state
        this.$store.commit('search/searchInput', {
          searchText: null,
          filters: {}
        });
        this.$store.commit('search/searchResult', null);
        // and redirect to the main page
        this.$router.push({ path: '/' });
      }
    }
  }
};
</script>
