<template>
  <div>
    <h5>
      Potential Data Owner
      <Tooltip
        direction="top"
        contents="Potential Data Owner that is being calculated suggested based on other attributes."
        class="inline"
      >
        <IconInformationCircle size="md" weight="fill" class="text-primary" />
      </Tooltip>
    </h5>

    <div v-if="loading" class="loading inline">
      <img src="spinner.svg" width="25" alt="loading" />
      Loading potential data owner...
    </div>

    <div v-else-if="error" class="loading inline">
      Error loading potential data owner
      <Button size="sm" @onClick="loadPotentialDataOwner">
        Retry
      </Button>
      {{ error }}
    </div>

    <div v-if="potentialDataOwners.length">
      <div v-for="potentialDataOwner in potentialDataOwners" :key="potentialDataOwner" style="margin-bottom: 4px">
        <button class="owner-btn btn btn-success">
          {{ potentialDataOwner.charAt(0).toUpperCase() }}
        </button>
        <a :href="`mailto:${potentialDataOwner}`" target="_blank"> {{ potentialDataOwner }} </a>
        <Tooltip
          direction="top"
          :contents="`${potentialDataOwnerSource}`"
          class="inline"
        >
          <IconInformationCircle size="md" weight="fill" class="text-primary" />
        </Tooltip>
      </div>
    </div>

    <div v-if="!potentialDataOwners.length">
      <span class="text-muted" style="padding-left: 0px;">
        No potential data owner could be determined.
      </span>
    </div>
  </div>
</template>
<script>
import { Tooltip, Button } from '@cimpress/react-components';
import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import { getCimpressEmployeeDetails, getCimpressSquadDetails } from '../../../clients/cimpressOrgChart';

export default {
  name: 'PotentialDataOwner',
  inject: ['platformClient'],
  components: {
    Button,
    IconInformationCircle,
    Tooltip
  },
  props: {
    tableInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      potentialDataOwners: [],
      potentialDataOwnerSource: null
    };
  },
  created() {
    this.loadPotentialDataOwner();
  },
  methods: {
    async loadPotentialDataOwner() {
      this.loading = true;
      this.error = null;
      this.potentialDataOwners = [];
      this.potentialDataOwnerSource = null;

      try {
        const { potentialDataOwners, source } = await this.getPotentialDataOwners();
        this.potentialDataOwners = potentialDataOwners;
        this.potentialDataOwnerSource = source;
      } catch (error) {
        this.error = error;
      }

      this.loading = false;
    },
    async getPotentialDataOwners() {
      let potentialDataOwners;

      // 1. Based on cimpress steward team email
      potentialDataOwners = await this.getStewardsTeamEmails(this.tableInfo.owners);
      if (potentialDataOwners.length) {
        return {
          potentialDataOwners,
          source: 'Based on table\'s Steward from Cimpress Technology team email'
        };
      }

      // 2. Based on schema data owner
      const schemaKey = `${this.tableInfo.database}://${this.tableInfo.cluster}.${this.tableInfo.schema}`;
      const schema = await this.platformClient.get(`${process.env.VUE_APP_AMUNDSEN_META_URL}/schema/${schemaKey}`);
      const schemaDataOwner = (schema.data.programmatic_descriptions || [])
        .find(b => b.source === 'squad_email');
      if (schemaDataOwner && schemaDataOwner.text) {
        const listOfSchemaDataOwners = JSON.parse(schemaDataOwner.text.replace(/'/g, '"'));
        if (listOfSchemaDataOwners && Array.isArray(listOfSchemaDataOwners) && listOfSchemaDataOwners.length) {
          return {
            potentialDataOwners: listOfSchemaDataOwners,
            source: 'Based on Schema data owner'
          };
        }
      }

      // 3. Based on schema's cimpress steward team email
      potentialDataOwners = await this.getStewardsTeamEmails(schema.data.owners);
      if (potentialDataOwners.length) {
        return {
          potentialDataOwners,
          source: 'Based on schema\'s Steward from Cimpress Technology team email'
        };
      }

      return {
        potentialDataOwners: [],
        source: null
      };
    },
    async getStewardsTeamEmails(owners) {
      let squadIds = (await Promise.all(owners.map(async o => {
        try {
          const employee = await getCimpressEmployeeDetails(this.platformClient, o.email);
          return employee._links.squad.id;
        } catch (err) {
          console.warn('Could not get employee details in OrgChart', err);
        }
        return null;
      }))).filter(id => id && id !== 'N/A');

      // deduplicate, if employees are within the same team
      squadIds = [...new Set(squadIds)];

      const teamEmails = (await Promise.all(squadIds.map(async squadId => {
        try {
          const squad = await getCimpressSquadDetails(this.platformClient, squadId);
          return squad.distributionList;
        } catch (err) {
          console.warn('Could not get squad details in OrgChart', err);
        }
        return null;
      }))).filter(id => id);

      return teamEmails;
    }
  }
};
</script>
