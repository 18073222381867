<template>
  <div style="margin: 25px">
    <div v-if="loadingTableInfo" align="center">
      <img src="spinner.svg" alt="loading" />
      <br /><br />
      <span>Loading table info...</span>
      <br /><br />
    </div>
    <error-popup
      v-else-if="tableInfoError"
      errorType="alert-danger-dismissible"
      :html="tableInfoError"
    />
    <div v-else>
      <div style="margin-bottom: 20px">
        <div>
          <TableHeader :keyValue="tableKeyDecoded" />
        </div>
      </div>

      <TableTabs v-if="tableInfo" :columnCount="columns.length" :itemKey="itemKey" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ErrorPopup from '../ErrorPopup.vue';
import TableHeader from './TableHeader';
import TableTabs from './TableTabs';

export default {
  name: 'Table',
  inject: ['userManager', 'platformClient'],
  components: {
    TableHeader,
    TableTabs,
    ErrorPopup
  },
  props: {
    itemKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tableKeyDecoded: ''
    };
  },
  computed: {
    ...mapState({
      tableInfo: state => state.tables.tableInfo,
      loadingTableInfo: state => state.tables.loadingTableInfo,
      tableInfoError: state => state.tables.tableInfoError,
      userDetails: state => state.customizr.userDetails
    }),
    columns() {
      return (this.tableInfo && this.tableInfo.columns) || 0;
    }
  },
  watch: {
    itemKey: {
      immediate: true,
      async handler() {
        if (!this.itemKey) {
          return;
        }

        const parts = this.itemKey.split('.');
        const cluster = `${parts[0]}.${parts[1]}`;
        const database = parts[2];
        const schema = parts[3];
        const objectName = parts[4];

        this.tableKey = `${database}://${cluster}.${schema}/${objectName}`;
        this.tableKeyDecoded = encodeURIComponent(this.tableKey);

        // Not awaited
        this.getTableInfo({
          platformClient: this.platformClient,
          keyValue: this.tableKeyDecoded
        });

        try {
          await this.getPii({
            platformClient: this.platformClient,
            account: cluster.replace('.eu-west-1', ''),
            database: database.toUpperCase(),
            schema: schema.toUpperCase(),
            object: objectName.toUpperCase()
          });
        } catch (err) {
          console.log(err);
        }

        try {
          this.initialiseVariablesToNull();

          // Warning: Don't change key names for tableData object. This is linked to customizr.
          const userData = {
            database,
            cluster,
            schema,
            table_name: objectName,
            key: this.tableKeyDecoded
          };
          await Promise.all([
            // user details apis for customizr
            this.updateUserRecentTables({ userData: userData }),
            this.putUserDetails({
              platformClient: this.platformClient,
              userDetailsObject: this.userDetails
            }),
            // nala embed apis
            this.frequentUsers({
              platformClient: this.platformClient,
              account: cluster.replace('.eu-west-1', ''),
              database: database,
              schema: schema,
              table_name: objectName
            }),
            // Looker grapher API
            this.getCachedLookerGrapherData({
              platformClient: this.platformClient,
              account: cluster.replace('.eu-west-1', ''),
              database: database,
              schema: schema,
              table_name: objectName
            }),
            this.getNewLookerGrapherData({
              platformClient: this.platformClient,
              account: cluster.replace('.eu-west-1', ''),
              database: database,
              schema: schema,
              table_name: objectName
            }),
            this.shareConsumers({
              platformClient: this.platformClient,
              account: cluster.replace('.eu-west-1', ''),
              database: database,
              schema: schema,
              table_name: objectName
            })
          ]);
        } catch (e) {
          console.log(e);
        }
      }
    }
  },
  async beforeDestroy() {
    await this.clearLookerGrapherData();
  },
  methods: {
    ...mapActions({
      getTableInfo: 'tables/getTableInfo',
      initialiseVariablesToNull: 'nala/initialiseVariablesToNull',
      frequentUsers: 'nala/frequentUsers',
      putUserDetails: 'customizr/putUserDetails',
      updateUserRecentTables: 'customizr/updateUserRecentTables',
      getNewLookerGrapherData: 'lookerGrapher/getNewLookerGrapherData',
      getCachedLookerGrapherData: 'lookerGrapher/getCachedLookerGrapherData',
      shareConsumers: 'nala/shareConsumers',
      clearLookerGrapherData: 'lookerGrapher/clearLookerGrapherData',
      getPii: 'pdwPii/getPii'
    })
  }
};
</script>
