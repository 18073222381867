<template>
  <div>
    <div>
      <h5 class="inline">
        {{ heading }}
      </h5>
      <Tooltip direction="top" :contents="tooltip" class="inline">
        <IconInformationCircle size="md" weight="fill" class="text-primary" />
      </Tooltip>
      <div class="inline pull-right" style="margin-right: 0px">
        <span
          class="pull-right customlink"
          style="padding-right: 0px"
          value="History"
          @click="isHistoryOn = true"
        >
          <Tooltip direction="top" contents="History">
            <IconSynchronizeArrowClock size="lg" />
          </Tooltip>
        </span>
        <EditHistoryTable
          v-if="isHistoryOn"
          :entityId="`${keyValue}/${entity}`"
          :entityType="heading"
          @closeModal="isHistoryOn = false"
        />
      </div>

      <div
        v-if="isUserSteward"
        class="inline pull-right"
        style="margin-right: 0px"
      >
        <button
          v-if="userManager.userData.app_metadata.canonical_id && !editMode && !this.isObjectReadOnly "
          class="btn-icon transparent"
          @click="
            editMode = !editMode;
            isLoading = false;
            editMode ? (userInput = '') : '';
          "
        >
          <Tooltip direction="top" contents="Edit">
            <i class="fa fa-pencil" aria-hidden="true" />
          </Tooltip>
        </button>
      </div>
      <!-- Add Slack Channel or Squad Email -->
      <div v-if="isUserSteward">
        <div v-if="isLoading && userInput" class="loading" style="padding: 10px 10px 10px 0;">
          <img src="spinner.svg" width="25" alt="loading" />
          Updating...
        </div>
        <span
          v-else-if="userManager.userData.app_metadata.canonical_id && editMode"
          class="col-xs-12"
          style="padding: 0; margin: 0;"
          @keyup.enter="userInput !== '' ? (confirmAdd = true) : ''"
        >
          <div
            style="padding: 0; margin: 0; width: 80%;"
            class="pull-left form-group"
            :class="[userInput ? 'form-group-active' : '', errorMessage ? 'has-error' : '']"
          >
            <label class="control-label" title="User Email">{{ heading }}</label>
            <input
              v-model="userInput"
              type="text"
              class="form-control"
              @click="(e) => { userInput = e.target.value; errorMessage = false}"
            />
            <small v-if="errorMessage && errorMessage != ''" class="help-block">{{ errorMessage }}</small>
            <br />
          </div>
          <div class="inline-edit-group pull-right" style="padding: 0; margin: 0; min-width: 20%; width: 20%;">
            <div class="right-addon">
              <button title="Add" @click="confirmAdd = false; updateFieldValue(userInput);">
                <i class="fa fa-check" style="margin: 0;" />
              </button>
              <button title="cancel">
                <i class="fa fa-times" style="margin: 0;" @click="editMode = !editMode; errorMessage = null;" />
              </button>
            </div>
          </div>
        </span>
      </div>
      <div v-if="programmaticFieldList.length === 0 && !editMode">
        <span class="text-muted" style="padding-left: 0px;">
          No
          {{ entity === 'slack_channel' ? 'slack channel' : 'data owner' }}
          added
        </span>
      </div>
      <!-- functionality for viewing slack_channel and squad_email fields data -->
      <span v-if="programmaticFieldList" style="padding: 0px">
        <div
          v-for="programmaticFieldData in programmaticFieldList"
          :key="programmaticFieldData"
          style="margin-bottom: 5px"
        >
          <div
            v-if="isLoading && valueToDelete === programmaticFieldData"
            class="loading"
            align="left"
          >
            <img src="spinner.svg" width="25" alt="loading" />
          </div>
          <div v-else>
            <button
              v-if="editMode && isUserSteward"
              class="pull-right btn btn-own transparent"
              @click="
                confirmDelete = true;
                valueToDelete = programmaticFieldData;
              "
            >
              <i v-if="!isLoading" class="fa fa-trash" aria-hidden="true" />
            </button>
            <button class="owner-btn btn btn-success">
              {{ programmaticFieldData.charAt(0).toUpperCase() }}
            </button>
            <a
              v-if="entity === 'slack_channel'"
              :href="`${getSlackWorkspace()}/app_redirect?channel=${programmaticFieldData.slice(
                1
              )}`"
              target="_blank"
            >
              {{ programmaticFieldData }}</a>
            <a
              v-if="entity === 'squad_email'"
              :href="`mailto:${programmaticFieldData}`"
              target="_blank"
            >
              {{ programmaticFieldData }}</a>
            <ConfirmBox
              v-if="confirmDelete"
              :title="`Remove ${heading}`"
              :isWarning=doesBreakStabilityCriteria()
              :message="warningMessageDelete"
              @confirmOperation="
                confirmDeleteOperation();
              "
              @closeModal="confirmDelete = false"
            />
          </div>
        </div>
      </span>
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import { IconSynchronizeArrowClock, IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import { clusterToSlackWorkspace } from '../../../utils/slackWorkspaceMapping';
import tableKeyToCluster from '../../../utils/tableKeyToCluster';
import ConfirmBox from '../../ConfirmBox';
import EditHistoryTable from '../../EditHistoryTable';

export default {
  name: 'OverviewFields',
  inject: ['userManager', 'platformClient'],
  components: {
    ConfirmBox,
    Tooltip,
    EditHistoryTable,
    IconSynchronizeArrowClock,
    IconInformationCircle
  },
  props: {
    keyValue: {
      type: String,
      default: () => ''
    },
    objectType: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      default: () => ''
    },
    entity: {
      type: String,
      default: () => ''
    },
    tooltip: {
      type: String,
      default: () => ''
    },
    isUserSteward: {
      type: Boolean,
      required: true
    },
    isStable: {
      type: Boolean,
      default: () => false
    },
    objectCluster: {
      type: String,
      required: false,
      default: () => 'cimpress.eu-west-1'
    }
  },
  data() {
    return {
      confirmAdd: false,
      confirmDelete: false,
      isHistoryOn: false,
      editMode: false,
      userInput: '',
      valueToDelete: '',
      userInputError: false,
      programmaticFieldList: [],
      isLoading: false,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      missingStabilityCriteria: state => state.tables.missingStabilityCriteria,
      isObjectReadOnly: state => state.tables.isObjectReadOnly
    }),
    customFields() {
      return this.$store.state[`${this.objectType}s`].customFields;
    },
    warningMessageAdd() {
      return `Are you sure you want to add <b>${this.userInput}</b> to ${this.heading} list for this Data object?`;
    },
    warningMessageDelete() {
      const message = `Are you sure you want to remove <b>${this.valueToDelete}</b> from ${this.heading}s list?`;
      if (this.doesBreakStabilityCriteria()) {
        return `Your dataset <b>won't be stable</b> anymore. ${message}`;
      }
      return message;
    }
  },
  watch: {
    userInput() {
      this.userInputError = false;
    }
  },
  created() {
    this.programmaticFieldList = this.customFields[this.entity].sort();
  },
  methods: {
    ...mapActions({
      putSlackChannel(dispatch, p) {
        return dispatch(`${this.objectType}s/putSlackChannel`, p);
      },
      putSquadEmail(dispatch, p) {
        return dispatch(`${this.objectType}s/putSquadEmail`, p);
      }
    }),
    async confirmDeleteOperation() {
      this.confirmDelete = false;
      const shouldDowngrade = this.doesBreakStabilityCriteria();
      this.deleteFieldValue();
      if (shouldDowngrade) {
        this.$emit('status:downgrade');
      }
    },
    doesBreakStabilityCriteria() {
      return this.programmaticFieldList.length === 1 && this.isStable && !this.missingStabilityCriteria.length;
    },
    async deleteFieldValue() {
      try {
        this.errorMessage = null;
        this.isLoading = true;
        let updateData = [...this.programmaticFieldList];
        updateData = updateData.filter(val => val !== this.valueToDelete);

        if (this.entity === 'slack_channel') {
          await this.putSlackChannel({
            platformClient: this.platformClient,
            keyValue: this.keyValue,
            slackChannel: updateData
          });
        } else if (this.entity === 'squad_email') {
          await this.putSquadEmail({
            platformClient: this.platformClient,
            keyValue: this.keyValue,
            squadEmail: updateData
          });
        }
        this.programmaticFieldList = this.customFields[this.entity].sort();
        this.valueToDelete = '';
      } catch (e) {
        this.errorMessage
          = e.message
          || e.data.message
          || e.data.title
          || (e.status && `Error occurred with status code ${e.status}`)
          || 'Unknown error occurred';
        console.log(e);
      }
      this.isLoading = false;
    },
    async updateFieldValue(inputValue) {
      try {
        this.errorMessage = null;
        this.isLoading = true;
        let updateData = [...this.programmaticFieldList, inputValue];
        if (this.entity === 'slack_channel') {
          await this.putSlackChannel({
            platformClient: this.platformClient,
            keyValue: this.keyValue,
            slackChannel: updateData
          });
        } else if (this.entity === 'squad_email') {
          await this.putSquadEmail({
            platformClient: this.platformClient,
            keyValue: this.keyValue,
            squadEmail: updateData
          });
        }
        this.programmaticFieldList = this.customFields[this.entity].sort();
        this.userInput = '';
      } catch (e) {
        this.errorMessage
          = e.message
          || e.data.message
          || e.data.title
          || (e.status && `Error occurred with status code ${e.status}`)
          || 'Unknown error occurred';
        console.log(e);
      }
      this.isLoading = false;
    },
    getSlackWorkspace() {
      if (this.isObjectReadOnly) {
        // If the object is read-only, it means it is a consumer object and we need to get the workspace from the producer object
        return clusterToSlackWorkspace[tableKeyToCluster(this.customFields.producer_object_key)];
      }
      return clusterToSlackWorkspace[this.objectCluster];
    }
  }
};
</script>
<style scoped>
.btn-own {
  margin: 0px;
  padding: 0px;
  color: #cb4154;
}
span {
  padding: 0px 5px 0px 11px;
}
</style>
